<template>
	<div id="app">
		<Stage />
	</div>
</template>

<script>
import Stage from "./components/Stage.vue";

export default {
	name: "App",
	components: {
		Stage,
	},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
#app {
	background:url('./assets/background.svg');
	min-height: 100vh;
	background-size:cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	padding-bottom:60px;
	box-sizing:border-box;
}
html {
	padding: 0px;
	margin: 0px;
	font-family: 'Ubuntu', sans-serif;
}
body {
	padding: 0px;
	margin: 0px;
}
</style>
