<template>
	<div>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			style="margin: auto;display: block; shape-rendering: auto;"
			width="247px"
			height="247px"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<g transform="rotate(0 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.9427609427609427s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(24 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.8754208754208753s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(48 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.808080808080808s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(72 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.7407407407407407s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(96 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.6734006734006733s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(120 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.606060606060606s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(144 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.5387205387205387s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(168 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.47138047138047134s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(192 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.404040404040404s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(216 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.33670033670033667s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(240 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.26936026936026936s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(264 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.202020202020202s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(288 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.13468013468013468s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(312 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="-0.06734006734006734s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(336 50 50)">
				<rect
					x="48"
					y="21"
					rx="0"
					ry="0"
					width="4"
					height="14"
					fill="#fffd38"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="1.01010101010101s"
						begin="0s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
		</svg>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
