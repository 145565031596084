<template>
  <div>
    <header>
      <div class="container">
        <div class="logo">
          <img src="@/assets/logo.svg" height="78" alt="" v-if="1 < 0" />
        </div>
        <div class="controls">
          <div
            class="controls__question"
            v-if="
              questions &&
                currentQuestionIndex <= questions.length &&
                questions.length > 0 &&
                step != 'finish'
            "
          >
            <span>{{ currentQuestionIndex + 1 }}</span> із
            {{ questions.length }}
          </div>
          <div class="controls__hero-title" v-if="currentHeroTitle">
            <div class="controls__hero-avatar">
              <img v-if="currentHeroAvatar" :src="currentHeroAvatar" alt="" />
            </div>
            <strong>{{ currentHeroTitle }}</strong>
          </div>
          <div class="controls__sound">
            <div class="mute" v-show="!mute" @click="mute = !mute">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 384 384"
                style="enable-background:new 0 0 384 384;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M288,192c0-37.653-21.76-70.187-53.333-85.867v171.84C266.24,262.187,288,229.653,288,192z"
                      />
                      <polygon
                        points="0,128 0,256 85.333,256 192,362.667 192,21.333 85.333,128 		"
                      />
                      <path
                        d="M234.667,4.907V48.96C296.32,67.307,341.333,124.373,341.333,192S296.32,316.693,234.667,335.04v44.053

C320.107,359.68,384,283.413,384,192S320.107,24.32,234.667,4.907z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="unmute" v-show="mute" @click="mute = !mute">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 384 384"
                style="enable-background:new 0 0 384 384;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M288,192c0-37.653-21.76-70.187-53.333-85.867v47.147l52.373,52.373C287.68,201.173,288,196.587,288,192z"
                      />
                      <path
                        d="M341.333,192c0,20.053-4.373,38.933-11.52,56.32l32.32,32.32C376,254.08,384,224,384,192

c0-91.307-63.893-167.68-149.333-187.093V48.96C296.32,67.307,341.333,124.373,341.333,192z"
                      />
                      <polygon points="192,21.333 147.413,65.92 192,110.507 		" />
                      <path
                        d="M27.2,0L0,27.2L100.8,128H0v128h85.333L192,362.667V219.2l90.773,90.773c-14.293,10.987-30.4,19.84-48.107,25.173V379.2

c29.333-6.72,56.107-20.16,78.613-38.613L356.8,384l27.2-27.2l-192-192L27.2,0z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div
            class="controls__menu"
            :class="{ opened: menu }"
            @click="menu = !menu"
          >
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
    </header>

    <div
      class="start-screen"
      :class="{ 'menu-opened': menu }"
      v-if="step == 'name' && !isLoading"
    >
      <div class="greeting-title" v-if="startPage">
        {{ startPage.acf.start_title }}
      </div>
      <div class="greeting-text" v-if="startPage">
        <div v-html="startPage.acf.start_text"></div>
      </div>
      <label>
        Введіть ваше ім'я щоб продовжити
      </label>
      <input type="text" v-model="user.name" placeholder="Ім'я" />
      <div class="gender-select">
        <label>
          Жінка
          <input type="radio" value="woman" v-model="user.gender" />
          <i></i>
        </label>
        <label>
          Чоловік
          <input type="radio" value="men" v-model="user.gender" />
          <i></i>
        </label>
      </div>
      <div>
        <button
          :disabled="user.name.length < 3 || !user.gender"
          @click="changeStep('game')"
        >
          Далі
        </button>
      </div>
    </div>

    <div class="menu" :class="{ opened: menu }">
      <ul>
        <li @click="restartGame">Почати знову</li>
      </ul>
    </div>
    <div class="stage" :class="{ 'menu-opened': menu }" v-if="step == 'game'">
      <div class="hero__step" v-if="currentHero == null && !isLoading">
        <h1 class="hero__title">
          Оберіть героя та розпочніть гру
        </h1>
        <carousel-3d
          v-if="heroes.length"
          :display="3"
          :inverse-scaling="inverseScaling"
          @before-slide-change="onBeforeSlideChange"
          @after-slide-change="onAfterSlideChange"
          :onMainSlideClick="selectHero"
          :controls-visible="true"
          :space="slideSpace"
          :width="slideWidth"
          :height="slideHeight"
          :perspective="0"
        >
          <slide v-for="(hero, i) in heroes" :key="hero.date" :index="i">
            <div
              class="image"
              v-if="
                (hero.acf.main_hero_image && user.gender == 'men') ||
                  (hero.acf.main_hero_image_woman && user.gender == 'woman')
              "
            >
              <template v-if="user.gender == 'woman'">
                <img :src="hero.acf.main_hero_image_woman" alt="" />
              </template>
              <template v-if="user.gender == 'men'">
                <img :src="hero.acf.main_hero_image" alt="" />
              </template>
            </div>
            <div class="image" v-else>
              <img src="@/assets/no_image.svg" alt="" />
            </div>
            <div class="name">
              {{ hero.acf.main_hero_name }}
            </div>
          </slide>
        </carousel-3d>
        <div class="hero__select-action">
          <button @click="selectHero">Розпочати гру</button>
        </div>
      </div>
      <div class="questions__step" v-if="currentHero != null">
        <div class="stage__header">
          <div
            class="hero"
            v-if="currentQuestionData.acf.hero_image"
            :class="{
              right:
                currentQuestionData.acf.hero_placement == 'right' &&
                !currentQuestionData.acf.additional_image,
            }"
          >
            <img :src="currentQuestionData.acf.hero_image" alt="" />
          </div>
          <div class="question" :class="{ animate: animate.q }">
            {{ currentQuestionData.acf.question_text }}
          </div>
          <div
            class="illustration"
            :class="{ animate: animate.q }"
            v-if="currentQuestionData.acf.additional_image"
          >
            <img :src="currentQuestionData.acf.additional_image" alt="" />
          </div>
        </div>

        <div class="answers-block">
          <div
            v-for="(a, i) in shuffledAnswers"
            :key="a.answer_text"
            class="answer"
            :class="{ animate: animate.a }"
            @click.prevent="answerAction(i)"
          >
            <p>
              <strong>{{ letters[i] }}</strong>
              {{ a.answer_text }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="finish"
      :class="{ show: step == 'finish' }"
      v-show="step == 'finish'"
    >
      <div class="card">
        <div class="finish__header">
          <h3>
            🏁 Гру завершено
          </h3>
          <div class="main-course" v-if="!isLoading && heroes && heroIndex">
            <div
              v-if="
                heroes && heroIndex && heroes[heroIndex].acf.victors_course_text
              "
              v-html="heroes[heroIndex].acf.victors_course_text"
            ></div>
            <div
              v-if="
                heroes && heroIndex && heroes[heroIndex].acf.victors_course_link
              "
            >
              <a
                :href="heroes[heroIndex].acf.victors_course_link"
                target="_blank"
                >Отримати курс</a
              >
            </div>
          </div>
        </div>
        <h2>
          🎯 Результати:
        </h2>
        <div class="score-table relative">
          <div v-for="(param, key, i) in resultParams" :key="key + i">
            {{ param_names[key] }}
            <span class="points">{{ param.points }}</span>
            <div class="range">
              <i
                :style="{
                  width: (param.points / 20) * 100 + '%',
                }"
              ></i>
            </div>
            <p>
              {{ param.text }}
            </p>
            <p
              v-html="courses[key].text"
              v-if="courses[key].text"
              class="course-description"
            ></p>
            <div v-if="courses[key].link" class="course-link">
              <a target="_blank" :href="courses[key].link">Перейти до курсу</a>
            </div>
          </div>
        </div>
        <div class="cta">
          <button @click="restartGame">
            Грати ще раз
          </button>
        </div>
        <div class="social__shares">
          <a
            :href="'viber://forward?text=' + baseURL + '?result=' + result.id"
            class="viber"
            >Ділитись результатом у Viber</a
          >
          <a
            :href="
              'https://telegram.me/share/url?url=' +
                baseURL +
                '?result=' +
                result.id
            "
            target="_blank"
            class="tg"
            >Ділитись результатом у Telegram</a
          >
          <a
            :href="
              'https://www.facebook.com/sharer/sharer.php?u=' +
                baseURL +
                '?result=' +
                result.id
            "
            target="_blank"
            class="fb"
            >Ділитись результатом у Facebook</a
          >
        </div>
      </div>
    </div>
    <!-- / finish -->

    <div
      class="finish"
      :class="{ show: step == 'result' }"
      v-show="step == 'result' && !menu"
    >
      <div class="card" v-if="result.content">
        <div class="finish__header">
          <h2 style="margin-bottom:30px">
            <span style="display:block;font-size:48px;margin-bottom:10px;"
              >🎯</span
            >
            Результати гравця
            <span>{{ result.content.user.name }}</span>
            <strong
              style="display:block;font-size:14px;margin-bottom:10px;margin-top:10px;color:#666;"
              >{{ result.content.user.hero }}</strong
            >
          </h2>
        </div>
        <div class="score-table relative">
          <div
            v-for="(param, key, i) in result.content.results.params"
            :key="key + i"
          >
            {{ param_names[key] }}
            <span class="points">{{ param.points }}</span>
            <div class="range">
              <i
                :style="{
                  width: (param.points / 20) * 100 + '%',
                }"
              ></i>
            </div>
            <p>
              {{ param.text }}
            </p>
            <p
              v-html="result.content.results.courses[key].text"
              v-if="result.content.results.courses[key].text"
              class="course-description"
            ></p>
            <div
              v-if="result.content.results.courses[key].link"
              class="course-link"
            >
              <a
                target="_blank"
                :href="result.content.results.courses[key].link"
                >Перейти до курсу</a
              >
            </div>
          </div>
        </div>
        <div class="cta">
          <button @click="restartGame">
            Зіграти
          </button>
        </div>
      </div>
    </div>

    <!--- result page -->

    <div
      class="questions__result-popup"
      :class="{ show: showResultPopup }"
      v-show="!menu"
      v-bind:key="popupKey"
    >
      <div class="card" :class="{ animate: showResultPopup }">
        <div class="image" v-if="currentAnswerResult.image">
          <img :src="currentAnswerResult.image" alt="" />
        </div>
        <div class="text" v-else>
          {{ currentAnswerResult.text }}
        </div>
        <div class="cta">
          <button @click="closePopUp">Продовжити</button>
        </div>
      </div>
    </div>
    <!-- / popup -->

    <div
      class="questions__result-popup"
      :class="{ show: showSkipPopup }"
      v-show="showSkipPopup"
    >
      <div class="card" :class="{ animate: showSkipPopup }">
        <strong style="font-size:36px;text-align:center;display-block;"
          >😉</strong
        >
        <div class="text">
          <div
            v-if="!isLoading && startPage"
            v-html="startPage.acf.skip_text"
          ></div>
        </div>
        <div class="cta">
          <button @click="restartGame">
            Грати ще раз
          </button>
        </div>
      </div>
    </div>
    <!-- / popup -->

    <footer v-if="!isLoading && startPage.acf && !menu">
      <div class="email" v-if="startPage.acf.email">
        <a :href="'mailto:' + startPage.acf.email">{{ startPage.acf.email }}</a>
      </div>
      <div class="website" v-if="startPage.acf.website">
        <a :href="startPage.acf.website">{{ startPage.acf.website }}</a>
      </div>
      <div class="facebook" v-if="startPage.acf.facebook_link">
        <a :href="startPage.acf.facebook_link">Ми у Facebok</a>
      </div>
      <div class="tel" v-if="startPage.acf.tel_1">
        <a :href="startPage.acf.tel_1">{{ startPage.acf.tel_1 }}</a>
      </div>
      <div class="tel" v-if="startPage.acf.tel_2">
        <a :href="startPage.acf.tel_2">{{ startPage.acf.tel_2 }}</a>
      </div>
      <div class="partner-logos">
        <a href="https://uifuture.org/" target="_blank">
          <img src="@/assets/uif_logo.png" alt="" />
        </a>
      </div>
    </footer>

    <div class="sounds">
      <audio
        id="audio-change-hero"
        src="@/assets/sounds/change_hero.mp3"
        preload="auto"
      ></audio>
      <audio
        id="audio-start-game"
        src="@/assets/sounds/start_game.mp3"
        preload="auto"
      ></audio>
      <audio
        id="audio-result-negative"
        src="@/assets/sounds/result_negative.mp3"
        preload="auto"
      ></audio>
      <audio
        id="audio-result-positive"
        src="@/assets/sounds/result_positive.mp3"
        preload="auto"
      ></audio>
    </div>
    <div class="loading-animation" v-if="isLoading">
      <loader />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Carousel3d, Slide } from "vue-carousel-3d";
import loader from "@/components/layout/loading.vue";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      baseURL: "",
      apiBaseUrl: "https://politgame-back.kspa.com.ua/",
      step: "name",
      user: {
        name: "",
        gender: null,
      },
      animate: {
        q: false,
        a: false,
      },
      showTip: false,
      menu: false,
      mute: false,
      isLoading: true,
      currentSlide: 0,
      slideWidth: 700,
      slideHeight: 320,
      slideSpace: 180,
      inverseScaling: 800,
      disable3d: true,
      rawData: null,
      questions: null,
      results: null,
      totalPoints: 0,
      currentHeroParamsDescr: null,
      courses: {
        law: {
          points: 0,
          text: "",
          link: "",
        },
        karma: {
          points: 0,
          text: "",
          link: "",
        },
        light: {
          points: 0,
          text: "",
          link: "",
        },
        corrupt: {
          points: 0,
          text: "",
          link: "",
        },
        public: {
          points: 0,
          text: "",
          link: "",
        },
      },
      resultParams: {
        law: {
          points: 0,
          text: "",
        },
        karma: {
          points: 0,
          text: "",
        },
        light: {
          points: 0,
          text: "",
        },
        corrupt: {
          points: 0,
          text: "",
        },
        public: {
          points: 0,
          text: "",
        },
      },
      popupKey: 1,
      heroes: [],
      heroIndex: 0,
      currentHero: null,
      currentHeroTitle: null,
      currentHeroImage: null,
      currentHeroAvatar: null,
      currentQuestionData: null,
      currentHeroCourses: null,
      currentQuestionIndex: 0,
      currentAnswerResult: {
        text: null,
        image: null,
        sound: null,
      },
      showResultPopup: false,
      letters: ["A", "B", "C", "D", "E"],
      footer: null,
      skip_count: 0,
      showSkipPopup: false,
      param_names: {
        law: "Законність",
        karma: "Карма",
        light: "Легковажність",
        corrupt: "Корумпованість",
        public: "Публічність",
      },
      result: {
        url: "",
        id: "",
        content: "",
      },
    };
  },
  components: {
    Carousel3d,
    Slide,
    loader,
  },
  computed: {
    shuffledAnswers: function() {
      var input = this.currentQuestionData.acf.question_answers;

      var last_index = 0;

      function shuffle(sourceArray) {
        for (var i = 0; i < sourceArray.length; i++) {
          //If not "last" option
          var j = i + Math.floor(Math.random() * (sourceArray.length - i));

          var temp = sourceArray[j];
          sourceArray[j] = sourceArray[i];
          sourceArray[i] = temp;
        }

        //find index of last answer
        for (var x = 0; x < sourceArray.length; x++) {
          // console.log(sourceArray[x]);
          if (sourceArray[x].answer_last) {
            last_index = x;
          }
        }

        //Move "last" option to the end of array
        sourceArray.push(sourceArray.splice(last_index, 1)[0]);
        return sourceArray;
      }

      var answers = shuffle(input);

      return answers;
    },
    volume: function() {
      if (this.mute) {
        return 0;
      } else {
        return 1;
      }
    },
    startPage: function() {
      var data = null;
      if (this.pages) {
        for (var i = 0; i < this.pages.length; i++) {
          if (this.pages[i].slug == "start-page") {
            data = this.pages[i];
          }
        }
      }
      return data;
    },
    finalResult: function() {
      var data;
      if (this.results) {
        for (var i = 0; i < this.results.length; i++) {
          if (this.totalPoints > this.results[i].acf.minimum_scores) {
            data = this.results[i];
          }
        }
      }
      return data;
    },
  },
  methods: {
    randomOrder() {
      var min = 1;
      var max = 9;
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    restartGame: function() {
      this.step = "name";
      this.currentHero = null;
      this.totalPoints = 0;
      this.questions = [];
      this.currentQuestionData = null;
      this.currentQuestionIndex = 0;
      this.showResultPopup = false;
      this.currentHeroTitle = null;
      this.menu = false;
      this.skip_count = 0;
      this.showSkipPopup = false;
    },
    onBeforeSlideChange() {
      this.playSoundChangeHero();
    },
    onAfterSlideChange(index) {
      this.heroIndex = index;
    },
    responsiveSlider() {
      var windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth >= 320) {
        this.slideWidth = 180;
        this.slideSpace = 500;
        this.slideHeight = 300;
        this.disable3d = false;
        this.inverseScaling = 1700;
      }
      if (windowWidth >= 768) {
        this.slideWidth = 220;
        this.slideSpace = 400;
        this.slideHeight = 350;
        this.inverseScaling = 800;
      }
      if (windowWidth >= 1200) {
        this.slideWidth = 360;
        this.slideSpace = 600;
        this.slideHeight = 360;
        this.inverseScaling = 800;
      }
    },
    changeStep: function(step) {
      this.step = step;
    },
    selectHero: function() {
      this.isLoading = true;
      this.currentHeroTitle = this.heroes[this.heroIndex].acf.main_hero_name;

      if (this.user.gender == "men") {
        this.currentHeroImage = this.heroes[this.heroIndex].acf.main_hero_image;
        //set hero avatar for men
        this.currentHeroAvatar = this.heroes[this.heroIndex].acf.hero_avatar;
      } else {
        this.currentHeroImage = this.heroes[
          this.heroIndex
        ].acf.main_hero_image_woman;

        //set hero avatar for woman
        this.currentHeroAvatar = this.heroes[
          this.heroIndex
        ].acf.hero_avatar_woman;
      }

      var hero = this.heroes[this.heroIndex].id;
      this.currentHero = hero;

      //Hero params descriptions
      this.currentHeroParamsDescr = this.heroes[
        this.heroIndex
      ].acf.parameters_descriptions;

      //Courses recomendations
      this.currentHeroCourses = this.heroes[
        this.heroIndex
      ].acf.course_recomendation;

      this.playSoundStartGame();
      this.getQuestions(hero);
    },
    closePopUp: function() {
      this.showResultPopup = false;
      setTimeout(() => {
        this.animate.q = true;
        this.animate.a = true;
      }, 400);
    },
    calcParamsText: function() {
      const dictionary = this.currentHeroParamsDescr;
      for (var key in this.resultParams) {
        var paramArray = dictionary[key];
        for (var i = 0; i < paramArray.length; i++) {
          if (
            parseFloat(paramArray[i].scores) ===
            parseFloat(this.resultParams[key].points)
          ) {
            this.resultParams[key].text = paramArray[i].text;
          }
        }
      }
    },
    calcCourses: function() {
      const dictionary = this.currentHeroCourses;
      for (var key in this.resultParams) {
        var paramArray = dictionary[key];
        for (var i = 0; i < paramArray.length; i++) {
          var score = paramArray[i].scores.split("-");
          var min = parseFloat(score[0]);
          var max = parseFloat(score[1]);
          if (
            min <= parseFloat(this.resultParams[key].points) &&
            parseFloat(this.resultParams[key].points) <= max
          ) {
            this.courses[key].text = paramArray[i].text;
            this.courses[key].link = paramArray[i].link;
          }
        }
      }
    },
    answerAction: function(index) {
      this.animate.q = false;
      this.animate.a = false;
      var points = this.shuffledAnswers[index].answer_points;
      var result_text = this.shuffledAnswers[index].answer_result;
      var result_image = this.shuffledAnswers[index].answer_image;
      var result_sound = this.shuffledAnswers[index].answer_sound;
      var result_parameter = this.shuffledAnswers[index].answer_parameter;
      if (this.shuffledAnswers[index].answer_last) {
        this.skip_count += 1;
      }

      this.currentAnswerResult.text = result_text;
      this.currentAnswerResult.image = result_image;
      this.currentAnswerResult.sound = result_sound;

      this.popupKey = this.popupKey + 1;

      if (result_text || result_image) {
        if (result_sound) {
          this.playSoundResult();
        }
        this.showResultPopup = true;
      } else {
        setTimeout(() => {
          this.animate.q = true;
          this.animate.a = true;
        }, 400);
      }

      if (this.skip_count == 7) {
        this.showResultPopup = false;
        this.showSkipPopup = true;
      }

      //Add points to total scores
      this.totalPoints += parseFloat(points);

      //Add points to selected parameter
      this.resultParams[result_parameter].points += parseFloat(points);

      this.calcParamsText();
      this.calcCourses();

      this.currentQuestionIndex += 1;
      //if not last question
      if (this.currentQuestionIndex < this.questions.length) {
        this.currentQuestionData = this.questions[this.currentQuestionIndex];
      } else {
        //otherwise show results
        this.step = "finish";
        this.postResult();
      }
      window.scrollTo(0, 0);
    },
    getPages: function() {
      axios.get(this.apiBaseUrl + "wp-json/wp/v2/pages/").then((response) => {
        this.pages = [];
        for (var i = 0; i < response.data.length; i++) {
          this.pages.push(response.data[i]);
        }
        this.getCategories();
      });
    },
    getCategories: function() {
      axios
        .get(this.apiBaseUrl + "wp-json/wp/v2/categories/")
        .then((response) => {
          this.heroes = [];
          for (var i = 0; i < response.data.length; i++) {
            if (
              response.data[i].slug !== "uncategorized" &&
              !response.data[i].acf.hide_hero
            ) {
              this.heroes.push(response.data[i]);
            }
          }
          this.getResults();
        });
    },
    getResults: function() {
      axios.get(this.apiBaseUrl + "wp-json/wp/v2/result/").then((response) => {
        this.results = [];
        for (var i = 0; i < response.data.length; i++) {
          this.results.push(response.data[i]);
        }
        this.responsiveSlider();
        this.slideAutoplay = false;
        this.isLoading = false;
      });
    },
    postResult: function() {
      this.postingResult = true;
      var rawStr = "results:Html#777";
      var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      var content = {
        user: {
          name: this.user.name,
          hero: this.currentHeroTitle,
        },
        results: {
          params: this.resultParams,
          courses: this.courses,
        },
      };
      var date = new Date();
      var url = this.apiBaseUrl + "/wp-json/wp/v2/people/";
      var title = this.user.name + " [" + this.currentHeroTitle + "] " + date;
      var post = {
        title: title,
        content: JSON.stringify(content),
        status: "publish",
      };
      axios({
        method: "post",
        url: url,
        headers: { Authorization: "Basic " + base64 },
        body: post,
        data: post,
      })
        .then((result) => {
          this.result.id = result.data.id;
          this.result.url =
            window.location.protocol +
            "//" +
            window.location.host +
            "?result=" +
            result.data.id;
          this.postingResult = false;
        })
        .catch((error) => {
          this.postingResult = false;
          console.log(error);
        });
    },
    getResultById: function() {
      var id = this.result.id;
      var url = this.apiBaseUrl + "/wp-json/wp/v2/people/?include[]=" + id;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        var content = response.data[0].content.rendered;
        content = content.replace("<p>", "");
        content = content.replace("</p>", "");
        content = content.replace(/&#8220;/g, '"');
        content = content.replace(/&#8221;/g, '"');
        content = content.replace(/&#8217;/g, "`");

        content = JSON.parse(content);
        this.result.content = content;
      });
    },
    getQuestions: function(id) {
      axios
        .get(
          this.apiBaseUrl +
            "wp-json/wp/v2/posts/?categories=" +
            id +
            "&per_page=100"
        )
        .then((response) => {
          this.rawData = response.data;
          this.questions = response.data;
          this.currentQuestionData = response.data[0];
          this.isLoading = false;

          setTimeout(() => {
            this.animate.q = true;
            this.animate.a = true;
          }, 300);
        });
    },
    playSoundResult() {
      var sound;
      if (this.currentAnswerResult.sound == "negative") {
        sound = document.getElementById("audio-result-negative");
      }
      if (this.currentAnswerResult.sound == "positive") {
        sound = document.getElementById("audio-result-positive");
      }
      sound.volume = this.volume;
      sound.play();
    },
    playSoundChangeHero() {
      var sound = document.getElementById("audio-change-hero");
      sound.volume = this.volume;
      sound.play();
    },
    playSoundStartGame() {
      var sound = document.getElementById("audio-start-game");
      sound.volume = this.volume;
      sound.play();
    },
  },
  mounted() {
    this.getPages();
  },
  created() {
    window.addEventListener("resize", () => {
      this.responsiveSlider();
    });
  },
  beforeMount() {
    this.baseURL = window.location.protocol + "//" + window.location.host;
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    var result = params.get("result");
    if (result) {
      this.result.id = result;
      this.getResultById();
      this.step = "result";
    }
  },
};
</script>

<style lang="scss">
@import "@/components/scss/reset.scss";
@import "@/components/scss/mixins.scss";

.score-table {
  position: fixed;
  bottom: 80px;
  right: 0px;
  background: #fff;
  padding: 10px;
  width: 220px;
  &.relative {
    position: relative;
    bottom: 0px;
    right: 0px;
    width: auto;
    .course-description {
      color: #000;
      font-size: 15px;
      margin-bottom: 10px;
      line-height: 1.3;
      padding: 10px;
      border-radius: 6px;
      background: rgba(#726cec, 0.2);
      margin-top: 20px;
    }
    .course-link {
      margin-bottom: 20px;
      a {
        font-weight: 600;
        font-size: 13px;
        color: #fff;
        background: #726cec;
        display: inline-block;
        padding: 5px 8px;
        border-radius: 6px;
        text-decoration: none;
        box-shadow: 0px 3px 3px 0px rgba(#000, 0.1);
      }
    }
    .range {
      height: 12px;
      margin-bottom: 10px;
    }
    & > div {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e7e7e7;
      .points {
        font-size: 13px;
        color: #f60;
      }
    }
  }
  .range {
    position: relative;
    height: 6px;
    background: #e7e7e7;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 5px;

    i {
      display: block;
      position: absolute;
      max-width: 100%;
      min-width: 3px;
      background: #f60;
      top: 0px;
      left: 0px;
      bottom: 0px;
      transition: 0.8s;
      background: linear-gradient(-45deg, #f60, #e73c7e, #f60);
      background-size: 200% 200%;
      animation: gradient 5s ease infinite;
    }
  }

  p {
    font-size: 13px;
    color: #999;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

.hero {
  &__title {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.8em;
  }

  &__select-action {
    padding: 30px 0px;
    text-align: center;

    button {
      height: 72px;
      // min-width: 300px;
      padding: 0px 30px;
      border-radius: 12px;
      background: #f60;
      border: none;
      color: #fff;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
      max-width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background: lighten(#f60, 5%);
        transition: 0.2s;
      }
    }
  }
}

.questions {
  &__step {
    position: relative;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__result-popup {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(#149344, 0.7);
    backdrop-filter: blur(10px);
    overflow-y: scroll;
    visibility: hidden;
    opacity: 0;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .card {
      transform: translateY(-60px);
      transition: 0.3s;
      // background: #fff;
      margin-top: 200px;
      margin-left: auto;
      margin-right: auto;
      width: 800px;
      max-width: 90vw;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition-delay: 0.2s;
      color: #fff;

      &.animate {
        transform: translateY(0px);
        transition: 0.3s;
      }
    }

    .image {
      text-align: center;

      img {
        height: 400px;
        max-width: 100%;
      }
    }

    .text {
      width: 100%;
      text-align: center;
      padding: 60px 30px;
      font-size: 28px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      flex: 1;
      font-weight: 400;
      p {
        margin-bottom: 10px;
      }
      a {
        color: #fff;
        font-weight: bold;
      }
    }

    .cta {
      text-align: center;
      padding: 30px 20px;

      button {
        height: 42px;
        min-width: 200px;
        padding: 0px 20px;
        border-radius: 8px;
        background: #f60;
        border: none;
        color: #fff;
        font-size: 1.5em;
        font-weight: 600;
        text-transform: uppercase;
        box-shadow: 0px 0px 30px 0px rgba(#000, 0.1);
        cursor: pointer;
        transition: 0.5s;

        &:hover {
          background: lighten(#f60, 5%);
          transition: 0.2s;
        }
      }
    }
  }
}

.carousel-3d-controls {
  a {
    color: yellow !important;
    font-size: 72px !important;
  }
}

.carousel-3d-slider {
  .carousel-3d-slide {
    background: none !important;
    border: none !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 12px;
    transition: 0.3s;

    .image {
      margin-bottom: 30px;
      min-width: 100%;
      margin-top: 15px;

      img {
        height: 250px;

        @media (max-width: 768px) {
          height: 200px;
        }
      }
    }

    .name {
      background: #149344;
      color: #fff;
      display: block;
      padding: 8px 12px;
      border-radius: 12px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    &.current {
      background: rgba(yellow, 0.1) !important;
      transition: 0.3s;

      .name {
        background: yellow;
        color: #000;
      }
    }
  }
}

.total_scores {
  position: fixed;
  right: 0px;
  bottom: 0px;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  background: #f00;
  padding: 20px 20px;
}

.answers-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;

  .answer {
    border-radius: 12px;
    padding: 15px 10px;
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 10px 0px;
    min-height: 96px;
    box-sizing: border-box;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 1.4;
    border: 4px solid rgba(#fff, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(#000, 0.2);
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: pointer;
    opacity: 0;
    transform: translateX(0px) scale3d(1, 0, 1);

    &.animate {
      transition: 0.3s;

      &:nth-child(1) {
        opacity: 1;
        transform: translateX(0) scale3d(1, 1, 1);
        transition: opacity 0.3s ease-in 0.2s, transform 0.2s ease-in 0.2s;
      }

      &:nth-child(2) {
        opacity: 1;
        transform: translateX(0) scale3d(1, 1, 1);
        transition: opacity 0.3s ease-in 0.25s, transform 0.2s ease-in 0.25s;
      }

      &:nth-child(3) {
        opacity: 1;
        transform: translateX(0) scale3d(1, 1, 1);
        transition: opacity 0.3s ease-in 0.3s, transform 0.2s ease-in 0.35s;
      }

      &:nth-child(4) {
        opacity: 1;
        transform: translateX(0) scale3d(1, 1, 1);
        transition: opacity 0.3s ease-in 0.35s, transform 0.2s ease-in 0.4s;
      }

      &:nth-child(5) {
        opacity: 1;
        transform: translateX(0) scale3d(1, 1, 1);
        transition: opacity 0.35s ease-in 0.4s, transform 0.2s ease-in 0.45s;
        margin-left: auto;
        margin-right: auto;
      }
    }

    p {
      position: relative;
      margin-left: 40px;

      strong {
        font-weight: bold;
        color: green;
        font-size: 24px;
        position: absolute;
        right: calc(100% + 20px);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      margin: 10px 0px;
    }

    &:hover {
      border-color: #fffd38;
      color: green;
      transition: 0.1s;
      box-shadow: 0px 0px 20px 0px rgba(#000, 0.4);
    }

    &:active {
      border-color: #fffd38;
      color: #fffd38;
    }
  }
}

.stage {
  width: 1024px;
  max-width: calc(100% - 60px);
  margin: 0px auto;
  padding: 15px;
  border-radius: 12px;
  padding-top: 100px;
  transition: 0.3s;

  &.menu-opened {
    transform: scale(0);
    transition: 0.3s;
  }

  &__header {
    display: flex;
    align-items: end;

    @media (max-width: 768px) {
      flex-wrap: wrap;

      img {
        max-width: 200px;
        margin-bottom: 20px;
        max-height: 96px;
      }
    }

    * {
      order: 1;
    }

    .hero {
      min-width: 25%;
      max-width: 25%;

      @media (max-width: 768px) {
        display: flex;
        max-width: 100%;
        justify-content: center;
      }

      &.right {
        order: 5;
      }
    }

    .illustration {
      padding: 15px;
      min-width: 25%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      flex-grow: 4;
      transform: translateX(20px);
      opacity: 0;
      transition: 0.3s;

      &.animate {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0.3s;
        transition: 0.3s;
      }

      @media (max-width: 768px) {
        max-width: 50%;
        justify-content: center;
        order: 2;
        max-height: 96px !important;
      }

      img {
        max-width: 100%;
        min-width: 100%;
        max-height: 200px;
      }
    }
  }

  .question {
    border: 4px solid #fffd38;
    color: #000;
    background: #fffd38;
    padding: 20px;
    border-radius: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 1.4;
    min-width: 48%;
    max-width: 300px;
    height: auto;
    position: relative;
    transform: translateX(-30px);
    transition: 0.3s;
    opacity: 0;

    &.animate {
      transform: translateX(0px);
      transition: 0.3s;
      opacity: 1;
    }

    &::before {
      right: 100%;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 26px 20px 0;
      border-color: transparent #fffc4e transparent transparent;
    }

    @media (max-width: 768px) {
      padding: 9px 15px;
      box-sizing: border-box;
      order: 9;
      min-width: 100%;
      font-size: 16px;
      line-height: 1.4;

      &::before {
        transform: translateY(-100%);
        top: 0px;
        left: 24px;
        border-width: 0 14px 16px 14px;
        border-color: transparent transparent #fffc4e transparent;
      }
    }
  }
}

.loading-animation {
  position: fixed;
  z-index: 99;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  backdrop-filter: blur(20px);
  z-index: 999;
  background: rgba(#019440, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(#019440, 0.3);

  .container {
    max-width: 100%;
    width: 1024px;
    margin: 0px auto;
    height: 80px;
    padding: 20px 15px;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  color: yellow;
  font-size: 32px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.menu {
  color: yellow;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  visibility: hidden;
  opacity: 0;
  user-select: none;

  ul {
    margin: 0px !important;

    li {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 30px;
      opacity: 0;
      transform: translateX(-25px);
      transition: 0.3s;
      padding: 5px 8px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: rgba(yellow, 0.2);
      }
    }
  }

  &.opened {
    visibility: visible;
    opacity: 1;

    ul {
      li {
        opacity: 1;
        transform: translateX(0px);
        @include transition;
      }
    }
  }
}

.controls {
  display: flex;
  align-items: center;

  &__question {
    margin-right: 15px;
    font-size: 14px;
  }

  &__hero-avatar {
    img {
      height: 64px;
    }
  }

  &__hero-title {
    margin-right: 10px;
    display: flex;
    align-items: center;

    strong {
      font-weight: 600;
      font-size: 13px;
      display: block;
      color: #000;
      background: yellow;
      padding: 4px 6px;
      border-radius: 8px;
      height: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 500px) {
        max-width: 80px;
      }
    }
  }

  &__sound {
    padding: 0px 15px;

    svg {
      height: 32px;
      fill: yellow;
      cursor: pointer;
    }
  }

  &__menu {
    position: relative;
    width: 32px;
    height: 28px;
    margin-left: 15px;
    cursor: pointer;

    i {
      position: absolute;
      display: block;
      height: 3px;
      background: yellow;
      width: 32px;
      transition: 0.3s;

      &:nth-child(1) {
        top: 0%;
        transform: translateY(-50%);
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        top: 100%;
        transform: translateY(-50%);
      }
    }

    &.opened {
      i {
        &:nth-child(1) {
          top: 50%;
          transform: rotate(45deg);
          transition: 0.3s;
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          top: 50%;
          transform: rotate(-45deg);
          transition: 0.3s;
        }
      }
    }
  }
}

.start-screen {
  padding-top: 130px;
  color: #fff;
  text-align: center;
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;

  .gender-select {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    label {
      margin: 5px 20px;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;

      i {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        background: transparent;
        border: 2px solid yellow;
        border-radius: 50%;
        margin-left: 10px;

        &::before {
          content: "";
          opacity: 0;
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: yellow;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      input {
        display: none;

        &:checked + i {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  button {
    height: 64px;
    // min-width: 300px;
    padding: 0px 30px;
    border-radius: 12px;
    background: #f60;
    border: none;
    color: #fff;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
    max-width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 10px;
    transition: 0.5s;
    width: 280px;

    &:disabled {
      color: rgba(#fff, 0.4);
    }

    &:hover {
      background: lighten(#f60, 5%);
      transition: 0.2s;
    }
  }

  .greeting-title {
    font-size: 28px;
    font-weight: bold;
  }

  .greeting-text {
    width: 800px;
    margin: 20px auto;
    line-height: 1.4;
    font-size: 18px;
    max-width: 100%;
    margin-bottom: 40px;

    p {
      margin-bottom: 20px;
    }
  }

  &.menu-opened {
    transform: scale(0);
    transition: 0.3s;
  }

  label {
    display: block;
    font-size: 22px;
    margin-bottom: 10px;
    color: yellow;
  }

  input[type="text"] {
    text-align: center;
    height: 48px;
    width: 280px;
    border-radius: 6px;
    border: 3px solid rgba(green, 0.6);
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0px 0px 15px 0px rgba(#000, 0.3);

    &:focus {
      border-color: yellow;
    }
  }
}

.finish {
  color: #000;
  width: 600px;
  max-width: 90vw;
  margin: 0px auto;
  transform: translateY(-60px);
  transition: 0.3s;
  visibility: hidden;
  padding: 100px 0px;
  h3 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h2 {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .card {
    padding: 30px;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 600px) {
      padding: 10px;
    }
  }

  &.show {
    visibility: visible;
    transform: translateY(0px);
    transition: 0.3s;
  }

  .text {
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
  }

  .image {
    text-align: center;
    background: #fff;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 20px;
    box-sizing: border-box;

    img {
      height: 160px;
    }
  }

  .cta {
    text-align: center;
    padding: 30px 20px;

    button {
      height: 48px;
      min-width: 310px;
      padding: 0px 20px;
      border-radius: 8px;
      background: #f60;
      border: none;
      color: #fff;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      box-shadow: 0px 0px 30px 0px rgba(#000, 0.1);
      cursor: pointer;
      transition: 0.5s;
      max-width: 100%;
      @media (max-width: 500px) {
        min-width: 100%;
      }

      &:hover {
        background: lighten(#f60, 5%);
        transition: 0.2s;
      }
    }
  }
}

footer {
  text-align: center;
  margin-top: 100px;

  .partner-logos {
    margin-top: 60px;
    margin-bottom: 40px;

    a {
      display: inline-block;
      margin-right: 25px;
      // filter: grayscale(100%);
      opacity: 0.8;
      transition: 0.3s;

      &:hover {
        filter: grayscale(0%);
        opacity: 1;
        transition: 0.3s;
      }
    }

    img {
      height: 64px;
    }
  }

  a {
    color: yellow;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }
  }

  div {
    margin-bottom: 15px;

    &.tel {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &.facebook {
      margin-bottom: 30px;
    }
  }
}
.main-course {
  padding: 10px;
  line-height: 1.4;
  a {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    background: #726cec;
    display: inline-block;
    padding: 5px 8px;
    border-radius: 6px;
    text-decoration: none;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
}
.social__shares {
  padding: 20px 0px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border-radius: 6px;
    width: 310px;
    margin: 5px auto 15px;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    @media (max-width: 600px) {
      max-width: 100%;
      font-size: 14px;
    }
    &.viber {
      background: #8f5db7;
      box-shadow: 0px 0px 0px 5px rgba(#8f5db7, 0.1);
    }

    &.tg {
      background: #0088cc;
      box-shadow: 0px 0px 0px 5px rgba(#0088cc, 0.1);
    }

    &.fb {
      background: #006bdd;
      box-shadow: 0px 0px 0px 5px rgba(#006bdd, 0.1);
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
